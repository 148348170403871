<template>
  <div class="text-ems-gray200 text-16px font-semibold leading-25px mb-10px">
    {{ t('event_verify.enter_keyword') }}
  </div>
  <div class="pr-14px">
    <InputFilter
      class="!rounded-8px event-verify"
      v-model:value="keyword"
      :show-count="false"
      :maxlength="100"
      :placeholder="t('event_verify.placeholder_keyword_other')"
      @input="onChangeInput"
    >
      <template #suffix>
        <img src="../../../static/img/icon/IconSearch.svg" alt="IconSearch" />
      </template>
    </InputFilter>
  </div>
  <div class="relative max-h-500px overflow-hidden mt-20px">
    <div v-if="isLoadingListProfile" class="spin-custom">
      <cds-loading />
    </div>
    <div
      class="flex flex-col gap-8px max-h-500px overflow-y-auto hidden-scroll pr-2px"
      @scroll="onScroll"
    >
      <div v-for="item in listProfileToMove" :key="item.id">
        <div
          class="relative px-20px py-8px w-full rounded-8px flex items-center gap-22px border-1px cursor-pointer hover:border-ems-main2"
          :class="
            selectedProfile === item.id
              ? 'border-ems-main2 bg-ems-gray600'
              : 'border-transparent bg-ems-gray700'
          "
          @click="selectedProfile = item.id"
        >
          <img
            :src="item.imagesAvatar"
            class="flex-shrink-0 w-52px h-52px object-cover rounded-[50%]"
            :alt="item.imagesAvatar"
          />
          <div class="flex flex-col w-full truncate">
            <div
              class="text-16px leading-25px text-ems-white font-semibold w-full truncate"
            >
              <span>{{ item.name ? item.name : item.id }}</span>
            </div>
            <ul
              class="list-disc list-inside text-12px text-ems-gray500 font-semibold pl-5px event-verify-ul"
            >
              <li class="leading-19px">
                {{ t('event_verify.date_of_birth') }}:
                {{ item.human ? formatDate(item.human.date_of_birth, 'DD/MM/YYYY') : '' }}
              </li>
              <li class="leading-19px">
                {{ t('event_verify.address') }}: {{ item.human ? item.human.address : ''}}
              </li>
              <li class="leading-19px">
                {{ t('event_verify.phone') }}: {{ item.human ? item.human.phone : ''}}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ClipButton
    class="mt-30px !px-10 mx-auto"
    :type="'primary'"
    :disabled="!selectedProfile"
    @click="onMoveToProfile"
  >
    <span>{{ t('event_verify.move_profile') }}</span>
  </ClipButton>
</template>
<script setup>
import { useI18n } from 'vue-i18n';
import { computed, onMounted, ref } from 'vue';
import InputFilter from '@/components/input-filter/index.vue';
import { useStore } from 'vuex';
import { formatDate } from '../../../util/common-utils';
import ClipButton from '@/components/buttons/ClipButton.vue';
import { debounce } from 'lodash';

const { t } = useI18n();
const { state, dispatch } = useStore();
const listProfileToMove = computed(() => state.eventVerify.listProfileToMove);
const keyword = ref('');
const selectedProfile = ref('');
const isLoadingListProfile = ref(false);
const pageListProfile = ref(0);
const getListProfileToMove = () => {
  pageListProfile.value = 0;
  dispatch('eventVerify/getListProfileToMove', {
    keyword: keyword.value,
    reset: true,
  });
  selectedProfile.value = '';
};
onMounted(() => {
  getListProfileToMove();
});
const onChangeInput = debounce(() => {
  getListProfileToMove();
}, 500);
const onScroll = async ({
  target: { scrollTop, clientHeight, scrollHeight },
}) => {
  if (scrollTop + clientHeight >= scrollHeight) {
    isLoadingListProfile.value = true;
    pageListProfile.value += 1;
    await dispatch('eventVerify/getListProfileToMove', {
      keyword: keyword.value,
      page: pageListProfile.value,
    });
    isLoadingListProfile.value = false;
  }
};
const emit = defineEmits(['moveToProfile'])
const onMoveToProfile = () => {
  emit('moveToProfile', selectedProfile.value)
};
</script>
